import { Actions } from "../../helpers/resource_helper";
import {
  ADD_PLAN,
  ADD_PLAN_ERROR,
  ADD_PLAN_SUCCESS,
  DELETE_PLAN,
  DELETE_PLAN_ERROR,
  DELETE_PLAN_SUCCESS,
  GET_PLAN,
  GET_PLAN_ERROR,
  GET_PLAN_SUCCESS,
  LIST_PLAN,
  LIST_PLAN_ERROR,
  LIST_PLAN_SUCCESS,
  RESET_PLAN,
  UPDATE_PLAN,
  UPDATE_PLAN_ERROR,
  UPDATE_PLAN_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  plans: [],
  plan: {},
  error: null,
  success: false,
  loading: false,
  limit: 0,
  page: 0,
  total: 0,
  action: Actions.EMPTY,
};

const Plan = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PLAN:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.LIST,
      };

    case LIST_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        plans: action.payload.items ?? [],
        limit: action.payload.limit ?? 0,
        page: action.payload.page ?? 0,
        total: action.payload.total ?? 0,
        action: Actions.EMPTY,
      };

    case LIST_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PLAN:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.GET,
      };

    case GET_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        plan: action.payload,
        action: Actions.EMPTY,
      };

    case GET_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case DELETE_PLAN:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.DELETE,
      };

    case DELETE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        plans: state.plans.filter((item) => item.id.toString() !== action.payload.id.toString()),
        action: Actions.EMPTY,
      };

    case DELETE_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_PLAN:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
        action: Actions.CREATE,
      };

    case ADD_PLAN_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        plans: [...state.plans, action.payload],
        plan: action.payload,
        action: Actions.EMPTY,
      };

    case ADD_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_PLAN:
      return {
        ...state,
        message: "",
        error: null,
        success: false,
        loading: true,
        action: Actions.UPDATE,
      };
    case UPDATE_PLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        plans: state.plans.map((plan) =>
          plan.id.toString() === action.payload.id.toString() ? { ...plan, ...action.payload } : plan,
        ),
        plan: action.payload,
        action: Actions.EMPTY,
      };

    case UPDATE_PLAN_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
        action: Actions.EMPTY,
      };

    case RESET_PLAN:
      return {
        ...state,
        plan: {},
        loading: false,
        action: Actions.EMPTY,
      };

    default:
      return { ...state };
  }
};

export default Plan;
