import {
  ADD_SERVICE,
  ADD_SERVICE_ERROR,
  ADD_SERVICE_SUCCESS,
  DELETE_SERVICE,
  DELETE_SERVICE_ERROR,
  DELETE_SERVICE_SUCCESS,
  GET_BUILT_IN_FEATURES,
  GET_BUILT_IN_FEATURES_ERROR,
  GET_BUILT_IN_FEATURES_SUCCESS,
  GET_SERVICE,
  GET_SERVICE_ERROR,
  GET_SERVICE_SUCCESS,
  LIST_SERVICE,
  LIST_SERVICE_ERROR,
  LIST_SERVICE_SUCCESS,
  RESET_SERVICE,
  UPDATE_SERVICE,
  UPDATE_SERVICE_ERROR,
  UPDATE_SERVICE_SUCCESS,
} from "./actionTypes";

export const listService = (payload) => ({
  type: LIST_SERVICE,
  payload,
});

export const listServiceSuccess = (data) => ({
  type: LIST_SERVICE_SUCCESS,
  payload: data,
});

export const listServiceFail = (error) => ({
  type: LIST_SERVICE_ERROR,
  payload: error,
});

export const getService = (payload) => ({
  type: GET_SERVICE,
  payload,
});

export const getServiceSuccess = (data) => ({
  type: GET_SERVICE_SUCCESS,
  payload: data,
});

export const getServiceFail = (error) => ({
  type: GET_SERVICE_ERROR,
  payload: error,
});

export const getBuiltInFeatures = () => ({
  type: GET_BUILT_IN_FEATURES,
});

export const getBuiltInFeaturesSuccess = (data) => ({
  type: GET_BUILT_IN_FEATURES_SUCCESS,
  payload: data,
});

export const getBuiltInFeaturesFail = (error) => ({
  type: GET_BUILT_IN_FEATURES_ERROR,
  payload: error,
});

export const deleteService = (payload, handleAfterSubmit = () => {}) => ({
  type: DELETE_SERVICE,
  payload: { payload, handleAfterSubmit },
});

export const deleteServiceSuccess = (data) => ({
  type: DELETE_SERVICE_SUCCESS,
  payload: data,
});

export const deleteServiceFail = (error) => ({
  type: DELETE_SERVICE_ERROR,
  payload: error,
});

export const updateService = (payload, handleAfterSubmit = () => {}) => ({
  type: UPDATE_SERVICE,
  payload: { payload, handleAfterSubmit },
});

export const updateServiceSuccess = (data) => ({
  type: UPDATE_SERVICE_SUCCESS,
  payload: data,
});

export const updateServiceFail = (error) => ({
  type: UPDATE_SERVICE_ERROR,
  payload: error,
});

export const addService = (payload, handleAfterSubmit = () => {}) => ({
  type: ADD_SERVICE,
  payload: { payload, handleAfterSubmit },
});

export const addServiceSuccess = (data) => ({
  type: ADD_SERVICE_SUCCESS,
  payload: data,
});

export const addServiceFail = (error) => ({
  type: ADD_SERVICE_ERROR,
  payload: error,
});

export const resetService = () => ({
  type: RESET_SERVICE,
});
