import { serviceApi } from "../../helpers/resource_helper";
import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  addServiceFail,
  addServiceSuccess,
  deleteServiceFail,
  deleteServiceSuccess,
  getBuiltInFeaturesFail,
  getBuiltInFeaturesSuccess,
  getServiceFail,
  getServiceSuccess,
  listServiceFail,
  listServiceSuccess,
  updateServiceFail,
  updateServiceSuccess,
} from "./actions";
import { ADD_SERVICE, DELETE_SERVICE, GET_BUILT_IN_FEATURES, GET_SERVICE, LIST_SERVICE, UPDATE_SERVICE } from "./actionTypes";
import { getBuiltInFeatures } from "../../helpers/service_helper";

function* listServiceSaga({ payload }) {
  try {
    const response = yield call(serviceApi.list, payload);
    yield put(listServiceSuccess(response));
  } catch (error) {
    yield put(listServiceFail(error));
  }
}

function* getServiceSaga({ payload: id }) {
  try {
    const response = yield call(serviceApi.get, { id });
    yield put(getServiceSuccess(response));
  } catch (error) {
    yield put(getServiceFail(error));
  }
}

function* getBuiltInFeaturesSaga() {
  try {
    const response = yield call(getBuiltInFeatures);
    yield put(getBuiltInFeaturesSuccess(response));
  } catch (error) {
    yield put(getBuiltInFeaturesFail(error));
  }
}
function* addServiceSaga({ payload: { payload, handleAfterSubmit } }) {
  try {
    const response = yield call(serviceApi.create, payload);
    yield put(addServiceSuccess(response));
    yield call(handleAfterSubmit, response);
  } catch (error) {
    console.log(error);
    yield put(addServiceFail(error));
  }
}
function* updateServiceSaga({ payload: { payload, handleAfterSubmit } }) {
  try {
    const response = yield call(serviceApi.put, payload);
    yield put(updateServiceSuccess(response));
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(updateServiceFail(error));
  }
}

function* deleteServiceSaga({ payload: { payload, handleAfterSubmit } }) {
  try {
    const response = yield call(serviceApi.delete, payload);
    yield put(deleteServiceSuccess(payload));
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(deleteServiceFail(error));
  }
}

export function* watchListService() {
  yield takeEvery(LIST_SERVICE, listServiceSaga);
}

export function* watchGetBuiltInFeatures() {
  yield takeEvery(GET_BUILT_IN_FEATURES, getBuiltInFeaturesSaga);
}

export function* watchGetService() {
  yield takeEvery(GET_SERVICE, getServiceSaga);
}

export function* watchAddService() {
  yield takeEvery(ADD_SERVICE, addServiceSaga);
}

export function* watchUpdateService() {
  yield takeEvery(UPDATE_SERVICE, updateServiceSaga);
}

export function* watchDeleteService() {
  yield takeEvery(DELETE_SERVICE, deleteServiceSaga);
}
function* serviceSaga() {
  yield all([
    fork(watchListService),
    fork(watchGetBuiltInFeatures),
    fork(watchGetService),
    fork(watchAddService),
    fork(watchUpdateService),
    fork(watchDeleteService),
  ]);
}

export default serviceSaga;
