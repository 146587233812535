import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";
import ChangePassword from "./auth/changepwd/reducer";
import ResetPassword from "./auth/resetpwd/reducer";

import Company from "./company/reducer";
import User from "./user/reducer";
//Inmage
import ImageUploader from "./uploadImage/reducer";

// Dashboard Analytics
import DashboardAnalytics from "./dashboardAnalytics/reducer";
import Subscriptions from "./subscription/reducer";
import Services from "./service/reducer";
import Plans from "./plan/reducer";

const rootReducer = combineReducers({
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ImageUploader,
  ChangePassword,
  ResetPassword,
  DashboardAnalytics,
  Company,
  Subscriptions,
  User,
  Services,
  Plans,
});

export default rootReducer;
