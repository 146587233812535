import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import ChangePassSaga from "./auth/changepwd/saga";
import ResetPassSaga from "./auth/resetpwd/saga";

//Image
import uploadImageSaga from "./uploadImage/saga";

// File Manager
import companySaga from "./company/saga";
import userSaga from "./user/saga";
// Dashboard Analytics
import dashboardAnalyticsSaga from "./dashboardAnalytics/saga";
import subscriptionSaga from "./subscription/saga";
import serviceSaga from "./service/saga";
import planSaga from "./plan/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ChangePassSaga),
    fork(ResetPassSaga),
    fork(ProfileSaga),
    fork(uploadImageSaga),
    fork(dashboardAnalyticsSaga),
    fork(companySaga),
    fork(subscriptionSaga),
    fork(userSaga),
    fork(serviceSaga),
    fork(planSaga),
  ]);
}
