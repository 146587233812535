import React, { useEffect, useState } from "react";
import ContentPageTheme from "../../Components/Common/ContentPageTheme";
import { Table } from "antd";
import { serviceApi } from "../../helpers/resource_helper";
import { formatDate } from "../../helpers/format_helper";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import StatusModal from "../../Components/Common/StatusModal";
import { cloneObjectOrArray, formatCurrency } from "../../common/constants";
import { toast } from "react-toastify";
import ModalSubscription from "./Components/ModalSubscription";
import SimpleBar from "simplebar-react";
import TableContainerCustom from "../../Components/Common/TableContainerCustom";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";
import { useDispatch } from "react-redux";
import { deleteService, listService } from "../../store/service/actions";
import withRouter from "../../Components/Common/withRouter";

const Services = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  const [openModalDeleteService, setOpenModalDeleteService] = useState(false);
  const [openModalSubscription, setOpenModalSubscription] = useState(false);
  const [selectService, setSelectService] = useState({});
  const [selectPlan, setSelectPlan] = useState({});
  const dispatch = useDispatch();

  const {
    serviceData,
    serviceLoading,
    serviceAction,
    serviceLimit,
    servicePage,
    serviceTotal,
    serviceMessage,
    serviceSuccess,
    serviceError,
  } = useMemoizedSelector((state) => ({
    serviceData: state.Services.services,
    serviceLoading: state.Services.loading,
    serviceAction: state.Services.action,
    serviceLimit: state.Services.limit,
    servicePage: state.Services.page,
    serviceTotal: state.Services.total,
    serviceMessage: state.Services.loading,
    serviceSuccess: state.Services.success,
    serviceError: state.Services.error,
  }));

  const handleAddSubscription = (service, dataPlan) => {
    setSelectPlan(dataPlan);
    setSelectService(service);
    setOpenModalSubscription(true);
  };

  const handleClickDeleteService = () => {
    dispatch(
      deleteService({ id: selectService.id }, () => {
        setOpenModalDeleteService(false);
      }),
    );
  };

  return (
    <ContentPageTheme title="List Service" pageTitle="Service">
      <SimpleBar
        className="file-manager-content w-100 p-3"
        style={{
          height: "calc(100vh - 226px)",
        }}
      >
        <div className="text-end my-3">
          <ButtonTheme
            className="btn btn-warning"
            onClick={() => {
              navigate("/services/new");
            }}
          >
            {"Add Service"}
          </ButtonTheme>
        </div>
        <TableContainerCustom
          filterType={"services"}
          tableView={true}
          searchPlaceHolder={"Search..."}
          headers={["name", "code", "description", "created_at", "updated_at", "action"]}
          contents={serviceData}
          limitPage={Number(serviceLimit)}
          currentPage={Number(servicePage) + 1}
          totalItems={serviceTotal}
          showPagination={true}
          scrollHeight="50vh"
          onCallData={(dataPayload) => {
            dispatch(listService(dataPayload));
          }}
          loading={serviceLoading}
          isSettings={true}
          listHeader={[
            {
              title: t("Product details"),
              key: "detail-product",
              children: [
                {
                  title: t("Name"),
                  dataIndex: "name",
                  key: "name",
                  sorter: true,
                  render: (value, row, index) => {
                    return (
                      <div className={"team-profile-img d-flex align-items-center "}>
                        <div className="team-content ">
                          <h5 className="fs-16 mb-1">{row?.name}</h5>
                          <p className="text-muted mb-0">{row?.domain}</p>
                        </div>
                      </div>
                    );
                  },
                },
                {
                  title: t("Code"),
                  dataIndex: "code",
                  key: "code",
                  sorter: true,
                  render: (value, row, index) => {
                    return (
                      <h5 className="mb-1 d-flex">
                        <strong>{value}</strong>
                      </h5>
                    );
                  },
                },
                {
                  title: "Description",
                  dataIndex: "description",
                  key: "description",
                  render: (value, row, index) => {
                    return (
                      <h5 className="mb-1 d-flex">
                        <strong>{value}</strong>
                      </h5>
                    );
                  },
                },
                {
                  title: "Created At",
                  dataIndex: "created_at",
                  key: "created_at",
                  defaultSortOrder: "desc",
                  render: (text) => formatDate(text),
                  sorter: true,
                },
                {
                  title: "Updated At",
                  dataIndex: "updated_at",
                  key: "updated_at",
                  render: (text) => formatDate(text),
                  sorter: true,
                },
                {
                  title: t("Action"),
                  dataIndex: "action",
                  key: "action",
                  render: (value, row, index) => {
                    return (
                      <div className="hstack gap-2">
                        <ButtonTheme
                          className={"btn btn-light btn-icon rounded-circle btn-sm"}
                          onClick={() => {
                            navigate(`/services/${row.id}`);
                          }}
                        >
                          <i className="ri-eye-line"></i>
                        </ButtonTheme>
                        <ButtonTheme
                          className={"btn btn-light btn-icon rounded-circle btn-sm"}
                          onClick={() => {
                            navigate(`/services/${row.id}/edit`);
                          }}
                        >
                          <i className="ri-edit-2-fill"></i>
                        </ButtonTheme>
                        <ButtonTheme
                          className={"btn btn-danger btn-icon rounded-circle btn-sm"}
                          onClick={() => {
                            setOpenModalDeleteService(true);
                            setSelectService(row);
                          }}
                        >
                          <i className="ri-delete-bin-2-fill"></i>
                        </ButtonTheme>
                      </div>
                    );
                  },
                },
              ],
            },
          ]}
        />
      </SimpleBar>
      <StatusModal
        show={openModalDeleteService}
        onClick={handleClickDeleteService}
        onCloseClick={() => setOpenModalDeleteService(false)}
        loading={serviceLoading}
      />
      <ModalSubscription
        open={openModalSubscription}
        toggle={() => setOpenModalSubscription(false)}
        selectService={selectService}
        selectPlan={selectPlan}
      />
    </ContentPageTheme>
  );
};

export default withRouter(Services);
