import React, { useState } from "react";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import ModalContainer from "../../../Components/Common/ModalContainer";
import { useTranslation } from "react-i18next";
import InputTheme from "../../../Components/Common/InputTheme";
import { Collapse } from "reactstrap";

const CustomFeatureModal = ({ initialPermissions, isOpen, toggle, onSave = () => {} }) => {
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(initialPermissions);
  const [expandedSections, setExpandedSections] = useState({});
  const [showAddSection, setShowAddSection] = useState(false);
  const [newSectionName, setNewSectionName] = useState("");
  const [editingSectionKey, setEditingSectionKey] = useState(null);

  // Form chung cho cả thêm và sửa permission
  const [permissionForm, setPermissionForm] = useState({
    type: null, // 'add' hoặc 'edit'
    section: null,
    oldKey: null, // dùng cho edit để lưu key cũ
    key: "",
    description: "",
  });

  const [editingSectionValue, setEditingSectionValue] = useState("");

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleDelete = (section, key) => {
    const newPermissions = { ...permissions };
    delete newPermissions[section][key];
    setPermissions(newPermissions);
  };

  const handleDeleteSection = (section) => {
    const newPermissions = { ...permissions };
    delete newPermissions[section];
    setPermissions(newPermissions);
  };

  // Hàm xử lý thêm/sửa permission
  const handlePermissionSubmit = () => {
    const { type, section, oldKey, key, description } = permissionForm;

    if (!key.trim() || !description.trim()) return;

    const newPermissions = { ...permissions };

    if (type === "edit") {
      // Xóa permission cũ nếu key thay đổi
      if (oldKey !== key) {
        delete newPermissions[section][oldKey];
      }
    }

    newPermissions[section] = {
      ...newPermissions[section],
      [key]: {
        description,
        active: false,
      },
    };

    setPermissions(newPermissions);
    setPermissionForm({
      type: null,
      section: null,
      oldKey: null,
      key: "",
      description: "",
    });
  };

  // Hàm mở form thêm permission
  const handleAdd = (section) => {
    setPermissionForm({
      type: "add",
      section,
      oldKey: null,
      key: "",
      description: "",
    });
  };

  // Hàm mở form sửa permission
  const handleEdit = (section, key, item) => {
    setPermissionForm({
      type: "edit",
      section,
      oldKey: key,
      key,
      description: item.description,
    });
  };

  // Hàm xử lý sửa key của section
  const handleEditSectionKey = (oldKey) => {
    setEditingSectionKey(oldKey);
    setEditingSectionValue(oldKey); // Khởi tạo giá trị edit bằng key hiện tại
  };

  const handleSaveSectionKey = () => {
    const oldKey = editingSectionKey;
    const newKey = editingSectionValue;

    if (!newKey.trim() || newKey === oldKey) {
      setEditingSectionKey(null);
      setEditingSectionValue("");
      return;
    }

    const newPermissions = { ...permissions };
    newPermissions[newKey] = newPermissions[oldKey];
    delete newPermissions[oldKey];

    setPermissions(newPermissions);
    setEditingSectionKey(null);
    setEditingSectionValue("");

    setExpandedSections((prev) => {
      const newExpanded = { ...prev };
      delete newExpanded[oldKey];
      newExpanded[newKey] = prev[oldKey] || false;
      return newExpanded;
    });
  };

  const handleCloseSectionEdit = () => {
    setEditingSectionKey(null);
    setEditingSectionValue("");
  };

  const handleAddSection = () => {
    if (!newSectionName.trim()) return;

    const newPermissions = {
      ...permissions,
      [newSectionName]: {
        _list: {
          description: `Xem danh sách ${newSectionName}`,
          active: false,
        },
      },
    };
    setPermissions(newPermissions);
    setNewSectionName("");
    setShowAddSection(false);
    setExpandedSections((prev) => ({
      ...prev,
      [newSectionName]: true,
    }));
  };

  return (
    <ModalContainer
      isOpen={isOpen}
      size="lg"
      title={"Custom features"}
      toggle={toggle}
      actionCustom={
        <div className="hstack gap-2 justify-content-end mt-3">
          <ButtonTheme className="btn btn-warning w-sm" onClick={() => {onSave(permissions)}}>
            {t("Save")}
          </ButtonTheme>
          <ButtonTheme
            type="button"
            className="btn btn-danger w-sm mx-2"
            onClick={() => {
              toggle();
            }}
          >
            {t("Close")}
          </ButtonTheme>
        </div>
      }
    >
      <div>
        <div className="hstack justify-content-between align-items-center mb-3">
          <h5>Quản lý Quyền</h5>
          <ButtonTheme className="btn btn-warning w-sm" onClick={() => setShowAddSection(true)}>
            <i className="ri-add-line"></i> Thêm Section Mới
          </ButtonTheme>
        </div>

        {showAddSection && (
          <div className="mb-4 p-4 border rounded-3 hstack gap-2">
            <div className="flex-1">
              <InputTheme
                isForm={false}
                placeholder="Nhập tên section mới"
                className="form-control w-md"
                onChange={(data) => setNewSectionName(data)}
                value={newSectionName}
                formNoMargin={true}
              />
            </div>
            <div className="hstack gap-2">
              <ButtonTheme className="btn btn-warning w-sm" onClick={handleAddSection}>
                {t("Add")}
              </ButtonTheme>
              <ButtonTheme
                className="btn btn-light w-sm"
                onClick={() => {
                  setShowAddSection(false);
                  setNewSectionName("");
                }}
              >
                {t("Close")}
              </ButtonTheme>
            </div>
          </div>
        )}

        <div className="d-flex flex-column gap-2">
          {Object.entries(permissions).map(([section, items]) => (
            <div key={section} className="border rounded-3 shadow-sm">
              <div className="hstack gap-2 justify-content-between px-2 py-3">
                {editingSectionKey === section ? (
                  <>
                    <div className="flex-1">
                      <InputTheme
                        isForm={false}
                        formNoMargin={true}
                        placeholder="Nhập tên section mới"
                        className="form-control"
                        value={editingSectionValue}
                        onChange={(data) => setEditingSectionValue(data)}
                      />
                    </div>
                    <ButtonTheme className="btn btn-warning w-sm" onClick={handleSaveSectionKey}>
                      {t("Save")}
                    </ButtonTheme>
                    <ButtonTheme className="btn btn-light w-sm" onClick={handleCloseSectionEdit}>
                      {t("Close")}
                    </ButtonTheme>
                  </>
                ) : (
                  <>
                    <div className="d-flex gap-2 flex-wrap align-items-center">
                      <span className="cursor-pointer" onClick={() => toggleSection(section)}>
                        {t(section)}
                      </span>
                      <i
                        className="ri-arrow-right-s-line fs-19 cursor-pointer"
                        onClick={() => toggleSection(section)}
                        style={{
                          transform: `${expandedSections[section] ? "rotate(90deg)" : "rotate(0deg)"}`,
                          transition: "all 0.3s ease",
                        }}
                      ></i>
                    </div>
                    <div className="hstack gap-2">
                      <ButtonTheme
                        className="btn btn-icon btn-outline-warning"
                        onClick={() => handleEditSectionKey(section)}
                      >
                        <i className="ri-pencil-line"></i>
                      </ButtonTheme>
                      <ButtonTheme className="btn btn-icon btn-danger" onClick={() => handleDeleteSection(section)}>
                        <i className="ri-delete-bin-line"></i>
                      </ButtonTheme>
                      <ButtonTheme
                        className="btn btn-icon btn-warning"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleAdd(section);
                          if (!expandedSections[section]) {
                            toggleSection(section);
                          }
                        }}
                      >
                        <i className="ri-add-line"></i>
                      </ButtonTheme>
                    </div>
                  </>
                )}
              </div>
              <Collapse isOpen={expandedSections[section]}>
                <div className="px-3">
                  {Object.entries(items).map(([key, item]) => (
                    <div key={key} className="flex items-center justify-between py-2 px-4">
                      {permissionForm?.section === section &&
                      permissionForm?.key === key &&
                      permissionForm.type === "edit" ? (
                        <div className="hstack gap-3">
                          <div className="hstack gap-3 flex-1">
                            <div className="flex-1">
                              <InputTheme
                                isForm={false}
                                placeholder="Nhập key permission"
                                className="form-control"
                                onChange={(data) => setPermissionForm((prev) => ({ ...prev, key: data }))}
                                value={permissionForm.key}
                                formNoMargin={true}
                              />
                            </div>
                            <div className="flex-1">
                              <InputTheme
                                isForm={false}
                                placeholder="Nhập mô tả permission"
                                className="form-control"
                                onChange={(data) => setPermissionForm((prev) => ({ ...prev, description: data }))}
                                value={permissionForm.description}
                                formNoMargin={true}
                              />
                            </div>
                          </div>
                          <ButtonTheme className="btn btn-warning w-sm" onClick={handlePermissionSubmit}>
                            {t("Save")}
                          </ButtonTheme>
                          <ButtonTheme
                            className="btn btn-light w-sm"
                            onClick={() => {
                              setPermissionForm({
                                type: null,
                                section: null,
                                oldKey: null,
                                key: "",
                                description: "",
                              });
                            }}
                          >
                            {t("Close")}
                          </ButtonTheme>
                        </div>
                      ) : (
                        <div className="hstack">
                          <div className="flex-1">
                            <p className="font-medium mb-0">
                              {key}: <span>{item.description}</span>
                            </p>
                          </div>
                          <div className="hstack gap-2">
                            <ButtonTheme
                              className="btn btn-icon btn-outline-warning"
                              onClick={() => handleEdit(section, key, item)}
                            >
                              <i className="ri-pencil-line"></i>
                            </ButtonTheme>
                            <ButtonTheme
                              className="btn btn-icon btn-outline-danger"
                              onClick={() => handleDelete(section, key)}
                            >
                              <i className="ri-delete-bin-line"></i>
                            </ButtonTheme>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  {permissionForm.type === "add" && permissionForm?.section === section && (
                    <div className="flex items-center justify-between py-2 px-4">
                      <div className="hstack gap-3">
                        <div className="hstack gap-3 flex-1">
                          <div className="flex-1">
                            <InputTheme
                              isForm={false}
                              placeholder="Nhập key permission"
                              className="form-control"
                              onChange={(data) => setPermissionForm((prev) => ({ ...prev, key: data }))}
                              value={permissionForm.key}
                              formNoMargin={true}
                            />
                          </div>
                          <div className="flex-1">
                            <InputTheme
                              isForm={false}
                              placeholder="Nhập mô tả permission"
                              className="form-control"
                              onChange={(data) => setPermissionForm((prev) => ({ ...prev, description: data }))}
                              value={permissionForm.description}
                              formNoMargin={true}
                            />
                          </div>
                        </div>
                        <ButtonTheme className="btn btn-warning w-sm" onClick={handlePermissionSubmit}>
                          {permissionForm.type === "add" ? t("Add") : t("Save")}
                        </ButtonTheme>
                        <ButtonTheme
                          className="btn btn-light w-sm"
                          onClick={() => {
                            setPermissionForm({
                              type: null,
                              section: null,
                              oldKey: null,
                              key: "",
                              description: "",
                            });
                          }}
                        >
                          {t("Close")}
                        </ButtonTheme>
                      </div>
                    </div>
                  )}
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      </div>
    </ModalContainer>
  );
};

export default CustomFeatureModal;
