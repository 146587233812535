import { apiCli, defaultApiCli } from "../helpers/api_helper";
const baseURL = "https://api-staging.onexapis.com";
const ULR_MESSAGE = "https://6fua85d22g.execute-api.ap-southeast-1.amazonaws.com";

export const getChannelAPI = () => {
  return apiCli.get(`${baseURL}/channels`);
};

export const getListDataDefault = (path, payload) => {
  return apiCli.get(path, { ...payload });
};

export const getBuiltInFeatures = () => {
  return defaultApiCli.get(`/admin/services/get_built_in_features`);
};