import {
  ADD_PLAN,
  ADD_PLAN_ERROR,
  ADD_PLAN_SUCCESS,
  DELETE_PLAN,
  DELETE_PLAN_ERROR,
  DELETE_PLAN_SUCCESS,
  GET_PLAN,
  GET_PLAN_ERROR,
  GET_PLAN_SUCCESS,
  LIST_PLAN,
  LIST_PLAN_ERROR,
  LIST_PLAN_SUCCESS,
  RESET_PLAN,
  UPDATE_PLAN,
  UPDATE_PLAN_ERROR,
  UPDATE_PLAN_SUCCESS,
} from "./actionTypes";

export const listPlan = (payload) => ({
  type: LIST_PLAN,
  payload,
});

export const listPlanSuccess = (data) => ({
  type: LIST_PLAN_SUCCESS,
  payload: data,
});

export const listPlanFail = (error) => ({
  type: LIST_PLAN_ERROR,
  payload: error,
});

export const getPlan = (payload) => ({
  type: GET_PLAN,
  payload,
});

export const getPlanSuccess = (data) => ({
  type: GET_PLAN_SUCCESS,
  payload: data,
});

export const getPlanFail = (error) => ({
  type: GET_PLAN_ERROR,
  payload: error,
});

export const deletePlan = (payload, handleAfterSubmit = () => {}) => ({
  type: DELETE_PLAN,
  payload: { payload, handleAfterSubmit },
});

export const deletePlanSuccess = (data) => ({
  type: DELETE_PLAN_SUCCESS,
  payload: data,
});

export const deletePlanFail = (error) => ({
  type: DELETE_PLAN_ERROR,
  payload: error,
});

export const updatePlan = (payload, handleAfterSubmit = () => {}) => ({
  type: UPDATE_PLAN,
  payload: { payload, handleAfterSubmit },
});

export const updatePlanSuccess = (data) => ({
  type: UPDATE_PLAN_SUCCESS,
  payload: data,
});

export const updatePlanFail = (error) => ({
  type: UPDATE_PLAN_ERROR,
  payload: error,
});

export const addPlan = (payload, handleAfterSubmit = () => {}) => ({
  type: ADD_PLAN,
  payload: { payload, handleAfterSubmit },
});

export const addPlanSuccess = (data) => ({
  type: ADD_PLAN_SUCCESS,
  payload: data,
});

export const addPlanFail = (error) => ({
  type: ADD_PLAN_ERROR,
  payload: error,
});

export const resetPlan = () => ({
  type: RESET_PLAN,
});
