import { planApi } from "../../helpers/resource_helper";
import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import {
  addPlanFail,
  addPlanSuccess,
  deletePlanFail,
  deletePlanSuccess,
  getPlanFail,
  getPlanSuccess,
  listPlanFail,
  listPlanSuccess,
  updatePlanFail,
  updatePlanSuccess,
} from "./actions";
import { ADD_PLAN, DELETE_PLAN, GET_PLAN, LIST_PLAN, UPDATE_PLAN } from "./actionTypes";

function* listPlanSaga({ payload }) {
  try {
    const response = yield call(planApi.list, payload);
    yield put(listPlanSuccess(response));
  } catch (error) {
    yield put(listPlanFail(error));
  }
}

function* getPlanSaga({ payload: id }) {
  try {
    const response = yield call(planApi.get, { id });
    yield put(getPlanSuccess(response));
  } catch (error) {
    yield put(getPlanFail(error));
  }
}
function* addPlanSaga({ payload: { payload, handleAfterSubmit } }) {
  try {
    const response = yield call(planApi.create, payload);
    yield put(addPlanSuccess(response));
    yield call(handleAfterSubmit, response);
  } catch (error) {
    console.log(error);
    yield put(addPlanFail(error));
  }
}
function* updatePlanSaga({ payload: { payload, handleAfterSubmit } }) {
  try {
    const response = yield call(planApi.put, payload);
    yield put(updatePlanSuccess(response));
    yield call(handleAfterSubmit, response);
  } catch (error) {
    yield put(updatePlanFail(error));
  }
}

function* deletePlanSaga({ payload: { payload, handleAfterSubmit } }) {
  try {
    const response = yield call(planApi.delete, payload);
    yield put(deletePlanSuccess(payload));
    yield call(handleAfterSubmit);
  } catch (error) {
    yield put(deletePlanFail(error));
  }
}

export function* watchListPlan() {
  yield takeEvery(LIST_PLAN, listPlanSaga);
}

export function* watchGetPlan() {
  yield takeEvery(GET_PLAN, getPlanSaga);
}

export function* watchAddPlan() {
  yield takeEvery(ADD_PLAN, addPlanSaga);
}

export function* watchUpdatePlan() {
  yield takeEvery(UPDATE_PLAN, updatePlanSaga);
}

export function* watchDeletePlan() {
  yield takeEvery(DELETE_PLAN, deletePlanSaga);
}
function* planSaga() {
  yield all([
    fork(watchListPlan),
    fork(watchGetPlan),
    fork(watchAddPlan),
    fork(watchUpdatePlan),
    fork(watchDeletePlan),
  ]);
}

export default planSaga;
