// Upload image
export const LIST_PLAN = "LIST_PLAN";
export const LIST_PLAN_SUCCESS = "LIST_PLAN_SUCCESS";
export const LIST_PLAN_ERROR = "LIST_PLAN_ERROR";

export const GET_PLAN = "GET_PLAN";
export const GET_PLAN_SUCCESS = "GET_PLAN_SUCCESS";
export const GET_PLAN_ERROR = "GET_PLAN_ERROR";

export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_ERROR = "UPDATE_PLAN_ERROR";

export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_ERROR = "DELETE_PLAN_ERROR";

export const ADD_PLAN = "ADD_PLAN";
export const ADD_PLAN_SUCCESS = "ADD_PLAN_SUCCESS";
export const ADD_PLAN_ERROR = "ADD_PLAN_ERROR";

export const RESET_PLAN = "RESET_PLAN";

