import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addPlan,
  addService,
  deletePlan,
  deleteService,
  getBuiltInFeatures,
  getPlan,
  getService,
  resetPlan,
  resetService,
  updatePlan,
  updateService,
} from "../../store/actions";
import ButtonTheme from "../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../Components/Hooks/useMemoizedSelector";
import withRouter from "../../Components/Common/withRouter";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Container,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Form, Formik } from "formik";
import InputTheme from "../../Components/Common/InputTheme";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import { deepCopy } from "../../common/constants";
import BreadCrumbCustom from "../../Components/Common/BreadCrumbCustom";
import StatusModal from "../../Components/Common/StatusModal";
import Loader from "../../Components/Common/Loader";

const Plans = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  const dispatch = useDispatch();

  const { id, service_id } = params;

  const editable = useMemo(() => id === "new", [id]);

  const {
    serviceData,
    serviceLoading,
    serviceAction,
    serviceLimit,
    servicePage,
    serviceTotal,
    serviceMessage,
    serviceSuccess,
    serviceError,
    builtInFeatures,
    builtInFeaturesLoading,
    builtInFeaturesSuccess,
    builtInFeaturesError,
  } = useMemoizedSelector((state) => ({
    serviceData: state.Services.service,
    serviceLoading: state.Services.loading,
    serviceAction: state.Services.action,
    serviceLimit: state.Services.limit,
    servicePage: state.Services.page,
    serviceTotal: state.Services.total,
    serviceMessage: state.Services.loading,
    serviceSuccess: state.Services.success,
    serviceError: state.Services.error,
    builtInFeatures: state.Services.builtInFeatures,
    builtInFeaturesLoading: state.Services.builtInFeaturesLoading,
    builtInFeaturesSuccess: state.Services.builtInFeaturesSuccess,
    builtInFeaturesError: state.Services.builtInFeaturesError,
  }));

  const { planData, planLoading, planAction, planLimit, planPage, planTotal, planMessage, planSuccess, planError } =
    useMemoizedSelector((state) => ({
      planData: state.Plans.plan,
      planLoading: state.Plans.loading,
      planAction: state.Plans.action,
      planLimit: state.Plans.limit,
      planPage: state.Plans.page,
      planTotal: state.Plans.total,
      planMessage: state.Plans.loading,
      planSuccess: state.Plans.success,
      planError: state.Plans.error,
    }));

  const refForm = useRef();
  const formValidation = {
    initialValues: {
      name: planData?.name || "",
      code: planData?.code || "",
      description: planData.description || "",
      price: planData.price || 0,
      service_id: service_id,
      sale_price: planData.sale_price || 0,
      features: planData?.features || {},
    },
    onSubmit: async (values, actions) => {
      if (id !== "new") {
        dispatch(
          updatePlan({ ...planData, ...values }, (data) => {
            navigate(`/services/${service_id}/plans/${data.id}`);
          }),
        );
      } else {
        dispatch(
          addPlan(values, (data) => {
            navigate(`/services/${service_id}/plans/${data.id}`);
          }),
        );
      }
    },
  };

  const permissions = useMemo(
    () =>
      serviceLoading ? {} : { ...(serviceData?.built_in_features ?? {}), ...(serviceData?.custom_features ?? {}) },

    [serviceData, serviceLoading],
  );

  const features = useMemo(
    () =>
      serviceLoading ? {} : { ...(builtInFeatures ?? {}), ...(serviceData?.custom_features ?? {}) },

    [serviceData, serviceLoading, builtInFeatures],
  );

  //state data
  const [deleteModal, setDeleteModal] = useState(false);

  const handleClickDeletePlan = () => {
    dispatch(
      deletePlan({ id }, () => {
        setDeleteModal(false);
        navigate(`/services/${service_id}`);
      }),
    );
  };

  useEffect(() => {
    dispatch(getService(service_id));
    dispatch(getBuiltInFeatures());
    if (id !== "new") {
      dispatch(getPlan(id));
    } else {
      dispatch(resetPlan());
    }
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <StatusModal
          show={deleteModal}
          onClick={handleClickDeletePlan}
          onCloseClick={() => setDeleteModal(false)}
          loading={planLoading}
        />
        <BreadCrumbCustom text="Back" navigateTo={`/services/${service_id}`}>
          <div className="hstack gap-2">
            {editable && (
              <ButtonTheme
                type="button"
                loadShowText={true}
                className="btn btn-primary w-sm"
                onClick={async () => {
                  refForm.current.handleSubmit();
                }}
              >
                {t(id === "new" ? "Create" : "Save")}
              </ButtonTheme>
            )}
            {!editable && (
              <ButtonTheme
                type="button"
                className="btn btn-danger w-sm"
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                {t("Delete")}
              </ButtonTheme>
            )}
          </div>
        </BreadCrumbCustom>
        {serviceLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={formValidation.initialValues}
            onSubmit={formValidation.onSubmit}
            innerRef={refForm}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <InputTheme name={`name`} label={"Name"} disabled={!editable} />
                      </Col>
                      <Col lg={6}>
                        <InputTheme name={`code`} label={"Code"} disabled={!editable} />
                      </Col>
                      <Col lg={6}>
                        <InputTheme name={`price`} label={"Price"} format="price" disabled={!editable} />
                      </Col>
                      <Col lg={6}>
                        <InputTheme name={`sale_price`} label={"Sale Price"} format="price" disabled={!editable} />
                      </Col>
                      <Col lg={12}>
                        <InputTheme name="description" type="textarea" label={"Description"} disabled={!editable} />
                      </Col>
                      <Col lg={12}>
                        <div className="align-items-center d-flex">
                          <CollapsePermissionCard
                            title="Features"
                            values={values.features}
                            onChangeData={(data) => {
                              setFieldValue(`features`, data);
                            }}
                            permissions={permissions}
                            editable={editable}
                            features={features}
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </div>
  );
};

const CollapsePermissionCard = ({
  title = "Built-in Features",
  values,
  permissions = null,
  onChangeData,
  editable = true,
  features = {},
}) => {
  const { t } = useTranslation();

  const filterPermissions = (data, filter) => {
    // Clone permissions object to avoid modifying original
    const result = JSON.parse(JSON.stringify(data));

    // Helper function to check if a module should be included
    const shouldIncludeModule = (moduleName, moduleFilter) => {
      if (!moduleFilter) return false;
      return Object.keys(moduleFilter).some((permission) => moduleFilter[permission]);
    };

    // Iterate through all modules in permissions
    Object.keys(result).forEach((moduleName) => {
      // Check if module exists in filter
      if (!filter[moduleName] || !shouldIncludeModule(moduleName, filter[moduleName])) {
        delete result[moduleName];
        return;
      }

      // Keep only filtered permissions within the module
      Object.keys(result[moduleName]).forEach((permission) => {
        if (!filter[moduleName][permission]) {
          delete result[moduleName][permission];
        } else {
          // Set active to true for filtered permissions
          result[moduleName][permission].active = true;
        }
      });
    });

    return result;
  };

  const filteredPermissions = useMemo(
    () => (permissions ? filterPermissions(features, permissions) : features),
    [features, permissions],
  );

  const fullPermissions = useMemo(
    () =>
      Object.keys(filteredPermissions).map((key) => ({
        key: key,
        label: key,
        children: Object.keys(filteredPermissions[key]).map((item) => ({
          key: item,
          label: filteredPermissions[key][item].description,
          defaultActive: filteredPermissions[key][item]?.active || false,
        })),
      })),
    [filteredPermissions],
  );

  const [collapse, setCollapse] = useState(true);
  return (
    <div className="mb-3">
      <Label
        className="mb-0 d-flex gap-2 flex-wrap align-items-center cursor-pointer"
        onClick={() => {
          setCollapse(!collapse);
        }}
      >
        {t(title)}
        <i
          className="ri-arrow-right-s-line fs-19"
          style={{
            transform: `${collapse ? "rotate(90deg)" : "rotate(0deg)"}`,
            transition: "all 0.3s ease",
          }}
        ></i>
      </Label>
      <Collapse isOpen={collapse}>
        {fullPermissions.length === 0 ? (
          <div>{t("No permission available")}</div>
        ) : (
          fullPermissions.map((permission, index) => (
            <CollapsePermission
              key={index}
              list={permission}
              onChangeData={(data) => {
                const newData = deepCopy(values ?? {});
                const newFeatures = { ...newData, ...data };
                onChangeData(newFeatures);
              }}
              dataSelect={values ?? {}}
              lastKey={fullPermissions.length - 1 === index ? true : false}
              disabled={!editable}
            />
          ))
        )}
      </Collapse>
    </div>
  );
};

const CollapsePermission = ({ list, onChangeData = () => {}, lastKey, dataSelect = {}, disabled = false }) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectAllChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { checked } = e.target;
    const keys = checked ? list.children.map((k) => k.key) : [];
    setSelectedRowKeys(keys);
    handleChange(keys);
  };

  const handleChange = (data) => {
    const obj = list.children.reduce((acc, current, index, array) => {
      acc[current.key] = data?.find((item) => item === current.key) ? true : false;
      return acc;
    }, {});

    onChangeData({
      [list.key]: obj,
    });
  };

  useEffect(() => {
    if (Object.keys(dataSelect).length > 0 && dataSelect[list.key]) {
      const arrayKey = Object.keys(dataSelect[list.key]);
      const filterArrayKey = arrayKey.filter((item) => dataSelect[list.key][item]);
      setSelectedRowKeys(filterArrayKey);
    }
  }, [dataSelect]);

  const formatString = (input) => {
    return input
      .split("_") // Split by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join with spaces
  };

  return (
    <div className={`${lastKey ? "" : "border-bottom"} `}>
      <div className="d-flex gap-2 flex-wrap align-items-center px-2 py-3">
        <Checkbox
          indeterminate={selectedRowKeys?.length > 0 && selectedRowKeys?.length < list.children?.length}
          checked={selectedRowKeys?.length === list.children?.length && selectedRowKeys?.length > 0}
          onChange={onSelectAllChange}
          disabled={disabled}
        />

        <div
          className="d-flex gap-2 flex-wrap align-items-center cursor-pointer"
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <span>{t(formatString(list.label))}</span>

          <i
            className="ri-arrow-right-s-line fs-19"
            style={{
              transform: `${collapse ? "rotate(90deg)" : "rotate(0deg)"}`,
              transition: "all 0.3s ease",
            }}
          ></i>
        </div>
      </div>
      <Collapse isOpen={collapse}>
        <Card className="mb-0">
          <CardBody>
            <Row>
              {list.children.map((permission, index) => (
                <Col lg={4} key={index}>
                  <Checkbox
                    disabled={disabled}
                    checked={selectedRowKeys.includes(permission?.key)}
                    onChange={(e) => {
                      const check = e.target.checked;
                      const keyRow = permission?.key;
                      if (check) {
                        const newData = [...selectedRowKeys, keyRow];
                        setSelectedRowKeys(newData);
                        handleChange(newData);
                      } else {
                        const newData = selectedRowKeys.filter((item) => item !== keyRow);
                        setSelectedRowKeys(newData);
                        handleChange(newData);
                      }
                    }}
                    className="mb-3"
                  >
                    {t(permission?.label)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default withRouter(Plans);
