import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addSubscription,
  deleteSubscription,
  getBuiltInFeatures,
  getPlan,
  getService,
  getSubscription,
  resetSubscription,
  updateSubscription,
} from "../../../store/actions";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import withRouter from "../../../Components/Common/withRouter";
import { Card, CardBody, Container, Label } from "reactstrap";
import { Form, Formik } from "formik";
import { DatePicker } from "antd";
import BreadCrumbCustom from "../../../Components/Common/BreadCrumbCustom";
import Loader from "../../../Components/Common/Loader";
import * as Yup from "yup";
import CKEditorCustom from "../../../Components/Common/CKEditorCustom";
import dayjs from "dayjs";
import SelectTheme from "../../../Components/Common/SelectTheme";
const { RangePicker } = DatePicker;

const SubscriptionDetails = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  const dispatch = useDispatch();

  const { id, plan_id, action } = params;

  const editable = useMemo(() => action === "edit" || id === "new", [action, id]);

  const {
    subscription,
    subscriptionLoading,
    subscriptionAction,
    subscriptionMessage,
    subscriptionSuccess,
    subscriptionError,
  } = useMemoizedSelector((state) => ({
    subscription: state.Subscriptions.subscription,
    subscriptionLoading: state.Subscriptions.loading,
    subscriptionAction: state.Subscriptions.action,
    subscriptionMessage: state.Subscriptions.loading,
    subscriptionSuccess: state.Subscriptions.success,
    subscriptionError: state.Subscriptions.error,
  }));

  const { planData, planLoading, planAction, planMessage, planSuccess, planError } = useMemoizedSelector((state) => ({
    planData: state.Plans.plan,
    planLoading: state.Plans.loading,
    planAction: state.Plans.action,
    planMessage: state.Plans.loading,
    planSuccess: state.Plans.success,
    planError: state.Plans.error,
  }));

  const { serviceData, serviceLoading, serviceAction, serviceMessage, serviceSuccess, serviceError } =
    useMemoizedSelector((state) => ({
      serviceData: state.Services.service,
      serviceLoading: state.Services.loading,
      serviceAction: state.Services.action,
      serviceMessage: state.Services.loading,
      serviceSuccess: state.Services.success,
      serviceError: state.Services.error,
    }));

  const rangePresets = [
    {
      label: "7 Days",
      value: [dayjs(), dayjs().add(7, "d")],
    },
    {
      label: "14 Days",
      value: [dayjs(), dayjs().add(14, "d")],
    },
    {
      label: "30 Days",
      value: [dayjs(), dayjs().add(30, "d")],
    },
    {
      label: "90 Days",
      value: [dayjs(), dayjs().add(90, "d")],
    },
  ];

  const refForm = useRef();
  const formValidation = {
    initialValues: {
      activation_date: subscription?.activation_date,
      expires_date: subscription?.expires_date,
      company_id: subscription?.company_id,
      contract_content: subscription?.contract_content || "",
      contract_annex: subscription?.contract_annex || "",
    },
    validationSchema: Yup.object({
      company_id: Yup.string().required(t("Please Select Company")),
    }),
    onSubmit: (values, actions) => {
      if (id !== "new") {
        const payload = {
          ...subscription,
          ...values,
        };
        dispatch(
          updateSubscription(payload, (data) => {
            navigate(`/subscriptions/${data.id}`);
          }),
        );
      } else {
        const payload = {
          ...values,
          service: serviceData,
          plan: planData,
        };
        dispatch(
          addSubscription(payload, (data) => {
            navigate(`/subscriptions/${data.id}`);
          }),
        );
      }
    },
  };

  useEffect(() => {
    if (id !== "new") {
      dispatch(getSubscription(id));
    } else {
      dispatch(getPlan(plan_id));
      dispatch(resetSubscription());
    }
  }, []);

  useEffect(() => {
    if (planData.service_id) {
      dispatch(getService(planData.service_id));
    }
  }, [planLoading, planData]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumbCustom text="Back to subscription list" navigateTo="/subscriptions">
          <div className="hstack gap-2">
            {editable && (
              <ButtonTheme
                type="button"
                loadShowText={true}
                className="btn btn-primary w-sm"
                onClick={async () => {
                  refForm.current.handleSubmit();
                }}
              >
                {t(id === "new" ? "Create" : "Save")}
              </ButtonTheme>
            )}
            {id !== "new" && (
              <ButtonTheme
                type="button"
                loadShowText={true}
                className="btn btn-light w-sm"
                onClick={() => navigate(action === "edit" ? `/subscriptions/${id}` : `/subscriptions/${id}/edit`)}
              >
                {t(action === "edit" ? "Close" : "Edit")}
              </ButtonTheme>
            )}
          </div>
        </BreadCrumbCustom>
        {subscriptionLoading ? (
          <div className="mb-3">
            <Loader />
          </div>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={formValidation.initialValues}
            onSubmit={formValidation.onSubmit}
            innerRef={refForm}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="d-block">Date</Label>
                      <RangePicker
                        value={[
                          values.activation_date ? dayjs(values.activation_date) : null,
                          values.expires_date ? dayjs(values.expires_date) : null,
                        ]}
                        style={{ width: "100%", marginBottom: "8px" }}
                        presets={rangePresets}
                        size="large"
                        onChange={(dates, dateStrings) => {
                          if (dates) {
                            setFieldValue("activation_date", dates[0].format("YYYY-MM-DDTHH:mm:ssZ"));
                            setFieldValue("expires_date", dates[1].format("YYYY-MM-DDTHH:mm:ssZ"));
                          } else {
                            setFieldValue("activation_date", null);
                            setFieldValue("expires_date", null);
                          }
                        }}
                      />
                    </div>
                    {id === "new" && (
                      <SelectTheme
                        className=""
                        label={"Company"}
                        name="company"
                        remote={true}
                        isObjectValueSelect = {true}
                        selectDataOrigin = {true}
                        path="admin/companies"
                      />
                    )}
                    <div className="mb-3">
                      <Label>Contract content</Label>
                      <div
                        style={{
                          border: `1px solid #d1d1d1`,
                          backgroundColor: "#fff",
                        }}
                      >
                        <CKEditorCustom
                          imagePrefix="subscriptions/contract_content"
                          value={values?.contract_content}
                          onChangeValue={(data) => {
                            setFieldValue("contract_content", data);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label>Contract annex</Label>
                      <div
                        style={{
                          border: `1px solid #d1d1d1`,
                          backgroundColor: "#fff",
                        }}
                      >
                        <CKEditorCustom
                          imagePrefix="subscriptions/contract_annex"
                          value={values?.contract_annex}
                          onChangeValue={(data) => {
                            setFieldValue("contract_annex", data);
                          }}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </div>
  );
};

export default withRouter(SubscriptionDetails);
