import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addService,
  deletePlan,
  deleteService,
  getBuiltInFeatures,
  getService,
  listPlan,
  resetService,
  updateService,
} from "../../../store/actions";
import ButtonTheme from "../../../Components/Common/ButtonTheme";
import useMemoizedSelector from "../../../Components/Hooks/useMemoizedSelector";
import withRouter from "../../../Components/Common/withRouter";
import { Card, CardBody, CardTitle, Col, Collapse, Container, Label, Row } from "reactstrap";
import { Form, Formik } from "formik";
import InputTheme from "../../../Components/Common/InputTheme";
import { useTranslation } from "react-i18next";
import { Checkbox } from "antd";
import UploadTheme from "../../../Components/Common/UploadTheme";
import { deepCopy } from "../../../common/constants";
import BreadCrumbCustom from "../../../Components/Common/BreadCrumbCustom";
import StatusModal from "../../../Components/Common/StatusModal";
import Loader from "../../../Components/Common/Loader";
import { formatDate } from "../../../helpers/format_helper";
import TableContainerCustom from "../../../Components/Common/TableContainerCustom";
import CustomFeatureModal from "../Components/CustomFeatureModal";

const ServiceDetails = (props) => {
  const {
    router: { location, navigate, params, t },
  } = props;
  const dispatch = useDispatch();

  const { id, action } = params;

  const editable = useMemo(() => action === "edit" || id === "new", [action, id]);

  const {
    serviceData,
    serviceLoading,
    serviceAction,
    serviceLimit,
    servicePage,
    serviceTotal,
    serviceMessage,
    serviceSuccess,
    serviceError,
    builtInFeatures,
    builtInFeaturesLoading,
    builtInFeaturesSuccess,
    builtInFeaturesError,
  } = useMemoizedSelector((state) => ({
    serviceData: state.Services.service,
    serviceLoading: state.Services.loading,
    serviceAction: state.Services.action,
    serviceLimit: state.Services.limit,
    servicePage: state.Services.page,
    serviceTotal: state.Services.total,
    serviceMessage: state.Services.loading,
    serviceSuccess: state.Services.success,
    serviceError: state.Services.error,
    builtInFeatures: state.Services.builtInFeatures,
    builtInFeaturesLoading: state.Services.builtInFeaturesLoading,
    builtInFeaturesSuccess: state.Services.builtInFeaturesSuccess,
    builtInFeaturesError: state.Services.builtInFeaturesError,
  }));

  const { plans, planLoading, planAction, planLimit, planPage, planTotal, planMessage, planSuccess, planError } =
    useMemoizedSelector((state) => ({
      plans: state.Plans.plans,
      planLoading: state.Plans.loading,
      planAction: state.Plans.action,
      planLimit: state.Plans.limit,
      planPage: state.Plans.page,
      planTotal: state.Plans.total,
      planMessage: state.Plans.loading,
      planSuccess: state.Plans.success,
      planError: state.Plans.error,
    }));

  const refForm = useRef();
  const formValidation = {
    initialValues: {
      name: serviceData?.name || "",
      code: serviceData?.code || "",
      description: serviceData.description || "",
      built_in_features: serviceData?.built_in_features || {},
      custom_features: serviceData?.custom_features || {},
      logo: serviceData.logo || null,
    },
    onSubmit: async (values, actions) => {
      if (id !== "new") {
        dispatch(
          updateService({ ...serviceData, ...values }, (data) => {
            navigate(`/services/${data.id}`);
          }),
        );
      } else {
        dispatch(
          addService(values, (data) => {
            navigate(`/services/${data.id}`);
          }),
        );
      }
    },
  };

  //state data
  const [deleteModal, setDeleteModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [deletePlanModal, setDeletePlanModal] = useState(false);

  const handleClickDeleteService = () => {
    dispatch(
      deleteService({ id }, () => {
        setDeleteModal(false);
        navigate("/services");
      }),
    );
  };

  const handleClickDeletePlan = () => {
    if (!selectedPlan?.id) {
      return;
    }
    dispatch(
      deletePlan({ id: selectedPlan.id }, () => {
        setDeletePlanModal(false);
      }),
    );
  };

  useEffect(() => {
    if (id !== "new") {
      dispatch(getService(id));
      dispatch(listPlan({ service_id: id }));
    } else {
      dispatch(resetService());
    }
    dispatch(getBuiltInFeatures());
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <StatusModal
          show={deleteModal}
          onClick={handleClickDeleteService}
          onCloseClick={() => setDeleteModal(false)}
          loading={serviceLoading}
        />
        <StatusModal
          show={deletePlanModal}
          onClick={handleClickDeletePlan}
          onCloseClick={() => setDeletePlanModal(false)}
          loading={planLoading}
        />
        <CustomFeatureModal
          isOpen={isOpen}
          toggle={() => setIsOpen(false)}
          initialPermissions={serviceData?.custom_features || {}}
          onSave={(data) => {
            refForm?.current?.setFieldValue("custom_features", data);
            setIsOpen(false);
          }}
        />
        <BreadCrumbCustom text="Back to service list" navigateTo="/services">
          <div className="hstack gap-2">
            {editable && (
              <ButtonTheme
                type="button"
                loadShowText={true}
                className="btn btn-primary w-sm"
                onClick={async () => {
                  refForm.current.handleSubmit();
                }}
              >
                {t(id === "new" ? "Create" : "Save")}
              </ButtonTheme>
            )}
            {id !== "new" && (
              <ButtonTheme
                type="button"
                loadShowText={true}
                className="btn btn-light w-sm"
                onClick={() => navigate(action === "edit" ? `/services/${id}` : `/services/${id}/edit`)}
              >
                {t(action === "edit" ? "Close" : "Edit")}
              </ButtonTheme>
            )}
            {!editable && (
              <ButtonTheme
                type="button"
                className="btn btn-danger w-sm"
                onClick={() => {
                  setDeleteModal(true);
                }}
              >
                {t("Delete")}
              </ButtonTheme>
            )}
          </div>
        </BreadCrumbCustom>
        {serviceLoading || builtInFeaturesLoading ? (
          <div className="mb-3">
            <Loader />
          </div>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={formValidation.initialValues}
            onSubmit={formValidation.onSubmit}
            innerRef={refForm}
          >
            {({ values, setFieldValue, setValues }) => (
              <Form>
                <Card>
                  <CardBody>
                    <div className="hstack gap-5 mb-3">
                      <div className="flex-1">
                        <InputTheme name="name" label={"Name"} disabled={!editable} />
                        <InputTheme name="code" label={"Code"} disabled={!editable} />
                      </div>
                      <div>
                        <UploadTheme name="logo" label={"Logo"} disabled={!editable} />
                      </div>
                    </div>
                    <InputTheme name="description" type="textarea" label={"Description"} disabled={!editable} />
                    <CollapsePermissionCard
                      values={values.built_in_features}
                      onChangeData={(data) => {
                        setFieldValue("built_in_features", data);
                      }}
                      editable={editable}
                      builtInFeatures={builtInFeatures}
                    />
                    <CollapsePermissionCard
                      title="Custom Features"
                      values={values.custom_features}
                      onChangeData={(data) => {
                        setFieldValue("custom_features", data);
                      }}
                      editable={editable}
                      builtInFeatures={values.custom_features}
                      toggle={() => {
                        setIsOpen(true);
                      }}
                      isCustom={true}
                    />
                  </CardBody>
                </Card>
              </Form>
            )}
          </Formik>
        )}
        {!editable && (
          <Card>
            <CardBody>
              <CardTitle className="hstack justify-content-between">
                {t("Plans")}{" "}
                <ButtonTheme
                  type="button"
                  onClick={() => {
                    navigate(`/services/${id}/plans/new`);
                  }}
                  className="btn btn-light border"
                >
                  <i className="ri-add-line me-1"></i>
                  {t("Add new plan")}
                </ButtonTheme>
              </CardTitle>
              <TableContainerCustom
                headers={["name", "code", "description", "created_at", "updated_at", "action"]}
                contents={plans}
                limitPage={Number(planLimit)}
                currentPage={Number(planPage) + 1}
                totalItems={planTotal}
                showPagination={true}
                scrollHeight="50vh"
                onCallData={(dataPayload) => {
                  dispatch(listPlan({ ...dataPayload, service_id: id }));
                }}
                loading={planLoading}
                isSettings={true}
                listHeader={[
                  {
                    title: t("Plan details"),
                    key: "detail-plan",
                    children: [
                      {
                        title: t("Name"),
                        dataIndex: "name",
                        key: "name",
                        sorter: true,
                        render: (value, row, index) => {
                          return (
                            <div className={"team-profile-img d-flex align-items-center "}>
                              <div className="team-content ">
                                <h5 className="fs-16 mb-1">{row?.name}</h5>
                                <p className="text-muted mb-0">{row?.domain}</p>
                              </div>
                            </div>
                          );
                        },
                      },
                      {
                        title: t("Code"),
                        dataIndex: "code",
                        key: "code",
                        sorter: true,
                        render: (value, row, index) => {
                          return (
                            <h5 className="mb-1 d-flex">
                              <strong>{value}</strong>
                            </h5>
                          );
                        },
                      },
                      {
                        title: "Description",
                        dataIndex: "description",
                        key: "description",
                        render: (value, row, index) => {
                          return (
                            <h5 className="mb-1 d-flex">
                              <strong>{value}</strong>
                            </h5>
                          );
                        },
                      },
                      {
                        title: "Created At",
                        dataIndex: "created_at",
                        key: "created_at",
                        defaultSortOrder: "desc",
                        render: (text) => formatDate(text),
                        sorter: true,
                      },
                      {
                        title: "Updated At",
                        dataIndex: "updated_at",
                        key: "updated_at",
                        render: (text) => formatDate(text),
                        sorter: true,
                      },
                      {
                        title: t("Action"),
                        dataIndex: "action",
                        key: "action",
                        render: (value, row, index) => {
                          return (
                            <div className="hstack gap-2">
                              <ButtonTheme
                                className={"btn btn-light btn-icon rounded-circle btn-sm"}
                                onClick={() => {
                                  navigate(`/services/${id}/plans/${row.id}`);
                                }}
                              >
                                <i className="ri-eye-line"></i>
                              </ButtonTheme>
                              <ButtonTheme
                                className={"btn btn-success btn-icon rounded-circle btn-sm"}
                                onClick={() => {
                                  navigate(`/plans/${row.id}/subscriptions/new`);
                                }}
                              >
                                <i className="bx bxs-cart-add"></i>
                              </ButtonTheme>
                              <ButtonTheme
                                className={"btn btn-danger btn-icon rounded-circle btn-sm"}
                                onClick={() => {
                                  setDeletePlanModal(true);
                                  setSelectedPlan(row);
                                }}
                              >
                                <i className="ri-delete-bin-2-fill"></i>
                              </ButtonTheme>
                            </div>
                          );
                        },
                      },
                    ],
                  },
                ]}
              />
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

const CollapsePermissionCard = ({
  title = "Built-in Features",
  values,
  permissions = null,
  onChangeData,
  editable = true,
  builtInFeatures = {},
  toggle = () => {},
  isCustom = false,
}) => {
  const { t } = useTranslation();

  const filterPermissions = (data, filter) => {
    console.log(data);
    console.log(filter);
    // Clone permissions object to avoid modifying original
    const result = JSON.parse(JSON.stringify(data));

    // Helper function to check if a module should be included
    const shouldIncludeModule = (moduleName, moduleFilter) => {
      if (!moduleFilter) return false;
      return Object.keys(moduleFilter).some((permission) => moduleFilter[permission]);
    };

    // Iterate through all modules in permissions
    Object.keys(result).forEach((moduleName) => {
      // Check if module exists in filter
      if (!filter[moduleName] || !shouldIncludeModule(moduleName, filter[moduleName])) {
        delete result[moduleName];
        return;
      }

      // Keep only filtered permissions within the module
      Object.keys(result[moduleName]).forEach((permission) => {
        if (!filter[moduleName][permission]) {
          delete result[moduleName][permission];
        } else {
          // Set active to true for filtered permissions
          result[moduleName][permission].active = true;
        }
      });
    });

    return result;
  };

  const filteredPermissions = useMemo(
    () => (permissions ? filterPermissions(builtInFeatures, permissions) : builtInFeatures),
    [builtInFeatures, permissions],
  );

  const fullPermissions = useMemo(
    () =>
      Object.keys(filteredPermissions).map((key) => ({
        key: key,
        label: key,
        children: Object.keys(filteredPermissions[key]).map((item) => ({
          key: item,
          label: filteredPermissions[key][item].description,
          defaultActive: filteredPermissions[key][item]?.active || false,
        })),
      })),
    [filteredPermissions],
  );

  const [collapse, setCollapse] = useState(true);
  return (
    <div className="mb-3">
      <div className="hstack justify-content-between">
        <Label
          className="mb-0 d-flex gap-2 flex-wrap align-items-center cursor-pointer"
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          {t(title)}
          <i
            className="ri-arrow-right-s-line fs-19"
            style={{
              transform: `${collapse ? "rotate(90deg)" : "rotate(0deg)"}`,
              transition: "all 0.3s ease",
            }}
          ></i>
        </Label>
        {isCustom && editable && (
          <ButtonTheme type="button" onClick={toggle} className="btn btn-light border">
            <i className="ri-add-line me-1"></i>
            {t("Edit custom features")}
          </ButtonTheme>
        )}
      </div>
      <Collapse isOpen={collapse}>
        {fullPermissions.length === 0 ? (
          <div>{t("No features available")}</div>
        ) : (
          fullPermissions.map((permission, index) => (
            <CollapsePermission
              key={index}
              list={permission}
              onChangeData={(data) => {
                const newData = deepCopy(values ?? {});
                const newFeatures = { ...newData, ...data };
                onChangeData(newFeatures);
              }}
              dataSelect={values ?? {}}
              lastKey={fullPermissions.length - 1 === index ? true : false}
              disabled={!editable}
              isCustom={isCustom}
            />
          ))
        )}
      </Collapse>
    </div>
  );
};

const CollapsePermission = ({
  list,
  onChangeData = () => {},
  lastKey,
  dataSelect = {},
  disabled = false,
  isCustom,
}) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectAllChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { checked } = e.target;
    const keys = checked ? list.children.map((k) => k.key) : [];
    setSelectedRowKeys(keys);
    handleChange(keys);
  };

  const handleChange = (data) => {
    const obj = list.children.reduce((acc, current, index, array) => {
      const check = data?.find((item) => item === current.key) ? true : false;
      if (isCustom) {
        const newData = {
          description: current.label,
          active: check,
        };
        acc[current.key] = newData;
      } else {
        acc[current.key] = check;
      }
      return acc;
    }, {});

    onChangeData({
      [list.key]: obj,
    });
  };

  useEffect(() => {
    if (Object.keys(dataSelect).length > 0 && dataSelect[list.key]) {
      const arrayKey = Object.keys(dataSelect[list.key]);
      const filterArrayKey = arrayKey.filter((item) =>
        isCustom ? dataSelect[list.key][item].active : dataSelect[list.key][item],
      );
      setSelectedRowKeys(filterArrayKey);
    }
  }, [dataSelect]);

  const formatString = (input) => {
    return input
      .split("_") // Split by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join with spaces
  };

  return (
    <div className={`${lastKey ? "" : "border-bottom"} `}>
      <div className="d-flex gap-2 flex-wrap align-items-center px-2 py-3">
        <Checkbox
          indeterminate={selectedRowKeys?.length > 0 && selectedRowKeys?.length < list.children?.length}
          checked={selectedRowKeys?.length === list.children?.length && selectedRowKeys?.length > 0}
          onChange={onSelectAllChange}
          disabled={disabled}
        />

        <div
          className="d-flex gap-2 flex-wrap align-items-center cursor-pointer"
          onClick={() => {
            setCollapse(!collapse);
          }}
        >
          <span>{t(formatString(list.label))}</span>

          <i
            className="ri-arrow-right-s-line fs-19"
            style={{
              transform: `${collapse ? "rotate(90deg)" : "rotate(0deg)"}`,
              transition: "all 0.3s ease",
            }}
          ></i>
        </div>
      </div>
      <Collapse isOpen={collapse}>
        <Card className="mb-0">
          <CardBody>
            <Row>
              {list.children.map((permission, index) => (
                <Col lg={4} key={index}>
                  <Checkbox
                    disabled={disabled}
                    checked={selectedRowKeys.includes(permission?.key)}
                    onChange={(e) => {
                      const check = e.target.checked;
                      const keyRow = permission?.key;
                      if (check) {
                        const newData = [...selectedRowKeys, keyRow];
                        setSelectedRowKeys(newData);
                        handleChange(newData);
                      } else {
                        const newData = selectedRowKeys.filter((item) => item !== keyRow);
                        setSelectedRowKeys(newData);
                        handleChange(newData);
                      }
                    }}
                    className="mb-3"
                  >
                    {t(permission?.label)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

export default withRouter(ServiceDetails);
